import React, { Suspense, lazy } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useParams,
} from 'react-router-dom';
const loader = document.querySelector('.loader-container');
// const showLoader = () => loader.classList.remove('loader--hide');
const hideLoader = () => loader.classList.add('loader--hide');
const Media = lazy(() => import('./components/Feeds'));
const allowedAccounts = ['almashhadmedia', 'almashhadsports'];

const App = () => {
  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}></Suspense>
      <Routes>
        <Route path="/:accountName" element={<AccountRoute />} />
        <Route path="/" element={<Navigate to="/almashhadmedia" />} />
        <Route path="*" element={<Navigate to="/almashhadmedia" />} />
      </Routes>
    </Router>
  );
};

const AccountRoute = () => {
  const { accountName } = useParams();
  if (allowedAccounts.includes(accountName)) {
    return <Media hideLoader={hideLoader} accountName={accountName} />;
  }
  return <Navigate to={`/${allowedAccounts[0]}`} />;
};

export default App;
